* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Lato;
    text-decoration: none;
}

body {
    /* background-color: #FFD7D7; */
    max-width: 1500px;
    margin: auto !important;
}

li {
    list-style-type: none;
}

textarea {
    align-items: center;
    width: 80%;
    height: 90px;
}