.footer_ul {
    display: flex;
    justify-content: space-around;
    background-color: #4E5166;
    text-decoration: none;
    /* margin-top: 5%; */
    /* margin-bottom: 5%; */
    padding-top: 2%;
    padding-bottom: 2%;
    border: 2px solid black;
    border-radius: 10px;
    box-shadow: 6px 3px 3px rgba(49, 94, 178, 0.303);

}

.footer_ul_li {
    color: white;
    font-size: small;
}

.footer_cop {
    display: flex;
    justify-content: space-around;
}

@media all and (min-width: 768px) and (max-width: 991px) {
    .footer_ul {
        flex-direction: row;
    }
}

@media all and (max-width: 768px) {
    .footer_ul {
        flex-direction: column;
    }

    .footer_ul_li {
        text-align: center;
        font-size: small;
        margin-bottom: 1%;
    }
}