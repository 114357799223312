.home {
    background-image: url(../../assets/bureauDev.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 120%;
}

.creation {
    text-align: center;
}

.home_texte {
    font-size: large;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 2%;
    width: 70%;
    font-size: x-large;
    color: white;
}

.home_texte2 {
    font-size: large;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    font-size: x-large;
    color: white;
}

.home_img {
    width: 40%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
}

.arroweb_texte {
    font-size: large;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 2%;
    width: 70%;
    font-size: large;
    color: white;
}

.video .video2 {
    width: 50%;
}

header {
    position: relative;
    z-index: 1;
    height: 700px;
    padding-top: 150px;
    text-align: center;
}

header .overlay {
    position: absolute;
    z-index: -1;
    inset: 0;
    background-color: #30303061;
}

header video {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

header h1 {
    color: #f1f1f1;
    font-weight: 800;
    font-size: 92px;
    text-shadow: 2px 2px 0px #111;
    text-transform: uppercase;
}

.services-link {
    color: #eee;
    font-size: 50px;
}

.services-link:hover {
    font-size: 60px;
}