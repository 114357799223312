.pagetarifs {
    background-color: #A9DFD7;
}

.titre2 {
    text-align: center;
    margin-top: 3%;
    margin-bottom: 2%;
    font-size: larger;
    border: solid;
    border-radius: 25%;
    margin-top: 3%;
    padding: 10%;

}

.titre2:hover {
    color: white;
    background-color: blue;
    box-shadow: 5px 5px 14px gray;
}

.blocformulaire {
    margin-bottom: 2%;
    display: flex;
    justify-content: center;
}

.prestations {
    display: flex;
    justify-content: center;
}

.prestations_bloc {
    width: 20%;
    padding: 2%;
    border: thick double red;
    margin-right: 1%;
}

.prestations_bloc_titre {
    text-align: center;
    margin-bottom: 1%;
    color: white;
}

.bloc1 {
    background-color: gray;
}

.bloc2 {
    background-color: black;
}

.prestations_bloc_texte {
    color: white;
}