/* @keyframes tourne {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
} */

@keyframes tire {
    0% {
        margin-left: 100%;
    }

    100% {
        margin-left: 1%;
    }
}

.flechedroite {
    animation-duration: 3s;
    animation-name: tire;
    animation-iteration-count: 3;
    animation-direction: alternate;
    margin-left: 10%;
    /* animation-iteration-count: infinite; */
}

.animationfleche {
    display: flex;
    background-color: #A9DFD7;
    padding-top: 4%;
}

.arroweb_titre {
    text-align: center;
    margin-top: 4%;
    margin-bottom: 0%;
}

.arroweb_texte {
    font-size: large;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0%;
    margin-bottom: 0%;
    width: 70%;
    font-size: large;
    color: black;
}

.blocArrow1 {
    background-image: url(../../assets/fondhaut.JPG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 120%;
}

.blocArrow2 {
    margin-top: 0%;
    padding-top: 2%;
    background-color: #A9DFD7;
}

.blocArrow3 {
    background-image: url(../../assets/fondbas.JPG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 120%;
    padding-top: 2%;
    padding-bottom: 4%;
}

@keyframes assuranceanimation {
    from {
        margin-left: 30%;
    }

    to {
        margin-left: 0%;
    }
}

.assurance {
    animation-duration: 3s;
    animation-name: assuranceanimation;
    animation-iteration-count: 3;
    animation-direction: alternate;
    padding-left: 10%;
    font-weight: bold;
    padding-left: 5%;
}