.navigation {
    font-size: large;
}

.navigation_ul {
    display: flex;
    justify-content: space-evenly;
    background-color: #4E5166;
    text-decoration: none;
    /* margin-top: 5%; */
    padding-top: 5%;
    padding-bottom: 5%;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    border-radius: 10px;
    box-shadow: 6px 3px 3px rgba(49, 94, 178, 0.303);
}

.navigation_ul_li {
    color: black;
}

.blocMenu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #A9DFD7;
}

.cible {
    width: 20%;
}

@media all and (min-width: 768px) and (max-width: 991px) {
    .navigation_ul {
        width: 100%;
        flex-direction: row;
    }
}


@media all and (max-width: 768px) {
    .navigation_ul {
        width: 100%;
        flex-direction: column;
    }

    .navigation_ul_li {
        text-align: center;
        font-size: small;
        margin-bottom: 1%;
    }
}