.arroweb_banner {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: url(../../assets/codeterre.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-color: #2D5674;
}

.arroweb_logo {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
}