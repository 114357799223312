@import url(https://fonts.googleapis.com/css?family=Merriweather);

html,
body {
    background: #f1f1f1;
    font-family: 'Merriweather', sans-serif;
    padding: 1em;
}

.titreForm {
    text-align: center;
    color: #a8a8a8;
}

form {
    max-width: 600px;
    text-align: center;
    margin: 20px auto;
}

input,
textarea {
    border: 0;
    outline: 0;
    padding: 1em;
    border-radius: 8px;
    display: block;
    width: 100%;
    margin-top: 1em;
    font-family: 'Merriweather', sans-serif;
    resize: none;
}

#input-submit {
    color: white;
    background: #e74c3c;
    cursor: pointer;
}

#input-submit:hover {
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
}

textarea {
    height: 126px;
}

.half {
    float: left;
    width: 48%;
    margin-bottom: 1em;
}

.right {
    width: 50%;
}

.left {
    margin-right: 2%;
}

@media (max-width: 480px) {
    .half {
        width: 100%;
        float: none;
        margin-bottom: 0;
    }
}

.cf:before,
.cf:after {
    content: ' ';
    display: table;
}

.cf:after {
    clear: both;
}